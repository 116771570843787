import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import {Figure, Figures} from "../../types/figure";
import {Colors} from "../../types/colors";
import useWebsocketEvent, {useWebsocketEventDispatch} from "../../hooks/useWebsocketEvent";
import {ClientEvent, ServerEvent} from "../../types/events";
import Player from "../../types/player";
import {BoardBlock, BoardContent, BoardTimer} from "./BoardStyles";
import {AvailableCells, Cells} from "../../types/cells";
import BoardCell from "./BoardCell/BoardCell";
import BoardFigure from "./BoardFigure/BoardFigure";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BoardController from "./BoardController/BoardController";
import {Magic, MagicNumber} from "../../types/magic";
import useInterval from "../../hooks/useInterval";
import moment from "moment";
import Game from "../../utils/game";
import {BoardEnd} from "./BoardEnd/BoardEnd";

interface ChosenFigure {
  figure: Figure;
  availableCells: AvailableCells;
  castlingCells: AvailableCells;
}

const Board: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const boardId = params.boardId;

  const dispatch = useWebsocketEventDispatch();
  const [isNewGameChanged, setIsNewGameChanged] = useState(true);

  const [isNobodyWin, setIsNobodyWin] = useState(false);
  const [currentSide, setCurrentSide] = useState<Colors | null>(Colors.WHITE);
  const [player, setPlayer] = useState<Player | null>(null);
  const [enemy, setEnemy] = useState<Player | null>(null);
  const [chosenFigure, setChosenFigure] = useState<ChosenFigure | null>(null);
  const [speededUpFigureStepsCounter, setSpeededUpFigureStepsCounter] = useState({
    [Colors.WHITE]: 0,
    [Colors.BLACK]: 0,
  });

  const [defendFigureStepsCounter, setDefendFigureStepsCounter] = useState({
    [Colors.WHITE]: 0,
    [Colors.BLACK]: 0,
  });

  const prevFreezeFigures = useRef<string[]>([]);
  const prevDefendFigures = useRef<string[]>([]);
  const prevSpeededFigures = useRef<string[]>([]);
  const prevFigures = useRef<string>("");

  useWebsocketEvent(ClientEvent.BoardGet, (data: any) => {
    const freeseFigures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isDefended)
        .map((figure) => figure.id);
    const defendFigures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isFrozen)
        .map((figure) => figure.id);
    const speededFigures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isSpeededUp)
        .map((figure) => figure.id);

    const figures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure)
        .map((figure) => figure.id)
        .join(",");

    prevFigures.current = figures;
    prevFreezeFigures.current = freeseFigures;
    prevDefendFigures.current = defendFigures;
    prevSpeededFigures.current = speededFigures;

    setCells(data.cells);
    setPlayer(data.player);
    setEnemy(data.enemy);
    setCurrentSide(data.currentSide);
    setWhiteTimer(data.whiteTimer);
    setBlackTimer(data.blackTimer);
    setBlackCooldown(data.blackCooldown);
    setWhiteCooldown(data.whiteCooldown);
    setSpeededUpFigureStepsCounter(data.speededUpFigureStepsCounter);
    setDefendFigureStepsCounter(data.defendFigureStepsCounter);
    setIsNobodyWin(data.isNobodyWin);
    if (data.player?.isNewGame) {
      setIsNewGameChanged(true);
      console.log("New game");
    } else {
      setIsNewGameChanged(false);
      console.log("Reconnect");
    }
  });


  // Вызываем функцию переподключения

  useEffect(() => {
    dispatch(ServerEvent.Reconnect, { boardId });

    dispatch(ServerEvent.BoardGet, { boardId });

  }, [boardId]);

  const [magicNumber, setMagicNumber] = useState<MagicNumber | null>(null);
  const [chosenMagic, setChosenMagic] = useState<Magic | null>(null);
  function changeMagic(magic: Magic, magicNumber: MagicNumber) {
    if (chosenMagic === magic) {
      setChosenMagic(null);
      setMagicNumber(null);
      return;
    }
    setMagicNumber(magicNumber);
    setChosenMagic(magic);
  }

  const figureClicked = async (figure: Figure) => {
    // Если нелья тыкать
    if (chosenMagic) {
      console.log(chosenMagic);
      console.log(magicNumber);
      if (chosenMagic === Magic.Freeze && figure.color !== player?.side) {
        dispatch(ServerEvent.FigureMagic, {
          magicType: chosenMagic,
          magicNumber: magicNumber,
          figureId: figure.id,
          boardId,
          playerId: player?.id,
        });

        setChosenMagic(null);
        setMagicNumber(null);
        return;
      }

      if (chosenMagic !== Magic.Freeze && figure.color === player?.side) {
        dispatch(ServerEvent.FigureMagic, {
          magicType: chosenMagic,
          magicNumber: magicNumber,
          figureId: figure.id,
          boardId,
          playerId: player?.id,
        });
      }

      setChosenMagic(null);
      setMagicNumber(null);
      return;
    }

    // Если фигуру можно сьесть
    if (chosenFigure?.availableCells[`${figure.x}-${figure.y}`] && chosenFigure?.figure.color !== figure.color) {
      moveOn(chosenFigure.figure, figure.x, figure.y);
      return;
    }

    if (player?.side !== figure.color || currentSide !== figure.color) return;

    // Если клик по той же фигуре
    if (chosenFigure?.figure.id === figure.id) {
      setChosenFigure(null);
      return;
    }

    // Если заморожена
    if (figure.isFrozen) return;

    // Если другая фигура ускорена
    // const speededUpFigure = getSpeededUpFigure(sides.ally);
    // if (speededUpFigure && speededUpFigure.id !== figure.id) {
    //   return;
    // }

    setChosenFigure({
      figure,
      availableCells: Game.getAvailableCells(cells, figure, "available"),
      castlingCells: checkedSide === player.side ? {} : Game.getCastlingCells(cells, figure),
    });
  };

  const [cells, setCells] = useState<Cells>({
    "1-1": {
      id: "white-rook-1-1",
      name: Figures.ROOK,
      x: 1,
      y: 1,
      color: Colors.WHITE,
    },
    "1-2": {
      id: "white-pawn-1-2",
      name: Figures.PAWN,
      y: 2,
      x: 1,
      color: Colors.WHITE,
    },
    "1-3": null,
    "1-4": null,
    "1-5": null,
    "1-6": null,
    "1-7": {
      id: "black-pawn-1-7",
      name: Figures.PAWN,
      y: 7,
      x: 1,
      color: Colors.BLACK,
    },
    "1-8": {
      id: "black-rook-1-8",
      name: Figures.ROOK,
      x: 1,
      y: 8,
      color: Colors.BLACK,
    },
    "2-1": {
      id: "white-knight-2-1",
      name: Figures.KNIGHT,
      x: 2,
      y: 1,
      color: Colors.WHITE,
    },
    "2-2": {
      id: "white-pawn-2-2",
      name: Figures.PAWN,
      y: 2,
      x: 2,
      color: Colors.WHITE,
    },
    "2-3": null,
    "2-4": null,
    "2-5": null,
    "2-6": null,
    "2-7": {
      id: "black-pawn-2-7",
      name: Figures.PAWN,
      y: 7,
      x: 2,
      color: Colors.BLACK,
    },
    "2-8": {
      id: "black-knight-2-8",
      name: Figures.KNIGHT,
      x: 2,
      y: 8,
      color: Colors.BLACK,
    },
    "3-1": {
      id: "white-bishop-3-1",
      name: Figures.BISHOP,
      x: 3,
      y: 1,
      color: Colors.WHITE,
    },
    "3-2": {
      id: "white-pawn-3-2",
      name: Figures.PAWN,
      y: 2,
      x: 3,
      color: Colors.WHITE,
    },
    "3-3": null,
    "3-4": null,
    "3-5": null,
    "3-6": null,
    "3-7": {
      id: "black-pawn-3-7",
      name: Figures.PAWN,
      y: 7,
      x: 3,
      color: Colors.BLACK,
    },
    "3-8": {
      id: "black-bishop-3-8",
      name: Figures.BISHOP,
      x: 3,
      y: 8,
      color: Colors.BLACK,
    },
    "4-1": {
      id: "white-queen-4-1",
      name: Figures.QUEEN,
      x: 4,
      y: 1,
      color: Colors.WHITE,
    },
    "4-2": {
      id: "white-pawn-4-2",
      name: Figures.PAWN,
      y: 2,
      x: 4,
      color: Colors.WHITE,
    },
    "4-3": null,
    "4-4": null,
    "4-5": null,
    "4-6": null,
    "4-7": {
      id: "black-pawn-4-7",
      name: Figures.PAWN,
      y: 7,
      x: 4,
      color: Colors.BLACK,
    },
    "4-8": {
      id: "black-queen-4-8",
      name: Figures.QUEEN,
      x: 4,
      y: 8,
      color: Colors.BLACK,
    },
    "5-1": {
      id: "white-king-5-1",
      name: Figures.KING,
      x: 5,
      y: 1,
      color: Colors.WHITE,
    },
    "5-2": {
      id: "white-pawn-5-2",
      name: Figures.PAWN,
      y: 2,
      x: 5,
      color: Colors.WHITE,
    },
    "5-3": null,
    "5-4": null,
    "5-5": null,
    "5-6": null,
    "5-7": {
      id: "black-pawn-5-7",
      name: Figures.PAWN,
      y: 7,
      x: 5,
      color: Colors.BLACK,
    },
    "5-8": {
      id: "black-king-5-8",
      name: Figures.KING,
      x: 5,
      y: 8,
      color: Colors.BLACK,
    },
    "6-1": {
      id: "white-bishop-6-1",
      name: Figures.BISHOP,
      x: 6,
      y: 1,
      color: Colors.WHITE,
    },
    "6-2": {
      id: "white-pawn-6-2",
      name: Figures.PAWN,
      y: 2,
      x: 6,
      color: Colors.WHITE,
    },
    "6-3": null,
    "6-4": null,
    "6-5": null,
    "6-6": null,
    "6-7": {
      id: "black-pawn-6-7",
      name: Figures.PAWN,
      y: 7,
      x: 6,
      color: Colors.BLACK,
    },
    "6-8": {
      id: "black-bishop-6-8",
      name: Figures.BISHOP,
      x: 6,
      y: 8,
      color: Colors.BLACK,
    },
    "7-1": {
      id: "white-knight-7-1",
      name: Figures.KNIGHT,
      x: 7,
      y: 1,
      color: Colors.WHITE,
    },
    "7-2": {
      id: "white-pawn-7-2",
      name: Figures.PAWN,
      y: 2,
      x: 7,
      color: Colors.WHITE,
    },
    "7-3": null,
    "7-4": null,
    "7-5": null,
    "7-6": null,
    "7-7": {
      id: "black-pawn-7-7",
      name: Figures.PAWN,
      y: 7,
      x: 7,
      color: Colors.BLACK,
    },
    "7-8": {
      id: "black-knight-7-8",
      name: Figures.KNIGHT,
      x: 7,
      y: 8,
      color: Colors.BLACK,
    },
    "8-1": {
      id: "white-rook-8-1",
      name: Figures.ROOK,
      x: 8,
      y: 1,
      color: Colors.WHITE,
    },
    "8-2": {
      id: "white-pawn-8-2",
      name: Figures.PAWN,
      y: 2,
      x: 8,
      color: Colors.WHITE,
    },
    "8-3": null,
    "8-4": null,
    "8-5": null,
    "8-6": null,
    "8-7": {
      id: "black-pawn-8-7",
      name: Figures.PAWN,
      y: 7,
      x: 8,
      color: Colors.BLACK,
    },
    "8-8": {
      id: "black-rook-8-8",
      name: Figures.ROOK,
      x: 8,
      y: 8,
      color: Colors.BLACK,
    },
  });
  const isAvailableCellForMove = (x: number, y: number): boolean => {
    return !!(chosenFigure && chosenFigure.availableCells[`${x}-${y}`]);
  };

  const isCellHavingFigure = (x: number, y: number): boolean => {
    return !!cells[`${x}-${y}`];
  };

  const moveOn = (figure: Figure, x: number, y: number) => {
    if (!player) return;

    dispatch(ServerEvent.FigureMakeStep, {
      boardId,
      playerId: player?.id,
      figureId: figure.id,
      x,
      y,
    });

    setChosenFigure(null);
  };

  const cellClicked = (x: number, y: number): void => {
    if (!chosenFigure) return;

    if (!chosenFigure.availableCells[`${x}-${y}`] && !chosenFigure.castlingCells[`${x}-${y}`]) return;

    moveOn(chosenFigure.figure, x, y);
  };

  const isEatableFigure = (figure: Figure | null): boolean => {
    if (!chosenFigure || !figure) return false;
    return chosenFigure.availableCells[`${figure.x}-${figure.y}`];
  };

  // const transformFigureModal = useMemo(() => {
  //   if (!player) {
  //     return false;
  //   }

  //   const figures = (Object.values(cells).filter((cell) => !!cell) as Figure[])
  //     .filter((figure) => figure.color === player.side)
  //     .filter((figure) => figure.name === Figures.PAWN);

  //   const y = player.side === Colors.WHITE ? 8 : 1;

  //   return figures.some((figure) => figure.y === y);
  // }, [player, cells]);

  const transformFigure = (figure: Figures) => {
    dispatch(ServerEvent.FigureTransform, {
      boardId,
      playerId: player?.id,
      figure,
    });
  };

  const initCells = useMemo(() => {
    const result = [];

    for (let y = 8; y >= 1; y--) {
      for (let x = 1; x <= 8; x++) {
        if ((y + x) % 2 !== 0) {
          result.push(
              <BoardCell
                  color={Colors.WHITE}
                  x={x}
                  y={y}
                  key={`${x}-${y}`}
                  isAvailableForMove={isAvailableCellForMove(x, y)}
                  isHavingFigure={isCellHavingFigure(x, y)}
                  onClick={cellClicked}
                  isCasting={!!chosenFigure && chosenFigure.castlingCells[`${x}-${y}`]}
                  figure={cells[`${x}-${y}`] as any}
                  isEatable={isEatableFigure(cells[`${x}-${y}`] as any)}
                  speededUpFigureStepsCounter={speededUpFigureStepsCounter}
                  defendFigureStepsCounter={defendFigureStepsCounter}
              />
          );
        } else {
          result.push(
              <BoardCell
                  color={Colors.BLACK}
                  x={x}
                  y={y}
                  key={`${x}-${y}`}
                  isAvailableForMove={isAvailableCellForMove(x, y)}
                  isHavingFigure={isCellHavingFigure(x, y)}
                  onClick={cellClicked}
                  isCasting={!!chosenFigure && chosenFigure.castlingCells[`${x}-${y}`]}
                  figure={cells[`${x}-${y}`] as any}
                  isEatable={isEatableFigure(cells[`${x}-${y}`] as any)}
                  speededUpFigureStepsCounter={speededUpFigureStepsCounter}
                  defendFigureStepsCounter={defendFigureStepsCounter}
              />
          );
        }
      }
    }

    return result;
  }, [cells, chosenFigure, chosenMagic]);

  const [winnerSide, setWinnerSide] = useState<Colors | null>(null);
  const [checkedSide, setCheckedSide] = useState<Colors | null>(null);

  const initFigures = useMemo(() => {
    const figuresJSX: JSX.Element[] = [];

    const figures = Object.values(cells).filter((cell) => !!cell) as Figure[];

    for (let item in figures) {
      figuresJSX.push(
          <BoardFigure
              onClick={figureClicked}
              onTransform={transformFigure}
              key={figures[item].id}
              figure={figures[item]}
              isInCheck={figures[item].name === Figures.KING && checkedSide === figures[item].color}
              side={player?.side ?? Colors.WHITE}
          />
      );
    }

    return figuresJSX;
  }, [cells, chosenFigure, chosenMagic]);

  const [whiteCooldown, setWhiteCooldown] = useState({
    [MagicNumber.FirstMagic]: 0,
    [MagicNumber.SecondMagic]: 0,
    [MagicNumber.ThirdMagic]: 0,
  });

  const [blackCooldown, setBlackCooldown] = useState({
    [MagicNumber.FirstMagic]: 0,
    [MagicNumber.SecondMagic]: 0,
    [MagicNumber.ThirdMagic]: 0,
  });

  const [whiteTimer, setWhiteTimer] = useState(0);
  const [blackTimer, setBlackTimer] = useState(0);

  const whiteTimerValue = useMemo(() => (whiteTimer > 0 ? whiteTimer : 0), [whiteTimer]);
  const blackTimerValue = useMemo(() => (blackTimer > 0 ? blackTimer : 0), [blackTimer]);

  useInterval(() => {
    if (currentSide === Colors.BLACK && (player?.side === Colors.BLACK || enemy?.side === Colors.BLACK)) {
      setBlackTimer((prev) => prev - 1000);
    }

    if (currentSide === Colors.WHITE && (player?.side === Colors.WHITE || enemy?.side === Colors.WHITE)) {
      setWhiteTimer((prev) => prev - 1000);
    }
  }, 1000);

  const freezeAudio = useRef<HTMLAudioElement | null>(null);
  const defendSecondlyAudio = useRef<HTMLAudioElement | null>(null);
  const speededAudio = useRef<HTMLAudioElement | null>(null);
  const stepAudio = useRef<HTMLAudioElement | null>(null);


  useEffect(() => {
    freezeAudio.current = loadSound("/sounds/freeze.mp3");
    freezeAudio.current?.addEventListener('canplaythrough', () => {
      console.log("Freeze audio is ready");
    });

    defendSecondlyAudio.current = loadSound("/sounds/defend-secondly.mp3");
    defendSecondlyAudio.current?.addEventListener('canplaythrough', () => {
      console.log("Defend Secondly audio is ready");
    });

    speededAudio.current = loadSound("/sounds/speedup.mp3");
    speededAudio.current?.addEventListener('canplaythrough', () => {
      console.log("Speeded audio is ready");
    });

    stepAudio.current = loadSound("/sounds/step.mp3");
    stepAudio.current?.addEventListener('canplaythrough', () => {
      console.log("Step audio is ready");
    });
  }, []);


  useWebsocketEvent(ClientEvent.BoardUpdate, async (data: any) => {
    const isUndefined = (value: any) => value === undefined;
    if (
        isUndefined(data.player) ||
        isUndefined(data.cells) ||
        isUndefined(data.currentSide) ||
        isUndefined(data.whiteCooldown) ||
        isUndefined(data.blackCooldown) ||
        isUndefined(data.checkedSide) ||
        isUndefined(data.winnerSide) ||
        isUndefined(data.whiteTimer) ||
        isUndefined(data.blackTimer) ||
        isUndefined(data.enemy)
    ) {
      return;
    }
    const defendFigures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isDefended)
        .map((figure) => figure.id);
    const freezeFigures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isFrozen)
        .map((figure) => figure.id);
    const speededFigures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isSpeededUp)
        .map((figure) => figure.id);
    const figures = (Object.values(data.cells).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure)
        .map((figure) => figure.x + "" + figure.y)
        .join(",");

    const currentSideDefendFigure = (Object.values(data.cells).filter((cell) => !!cell) as Figure[]).find(
        (figure) => figure.color === currentSide && !!figure.isDefended
    );

    if (
        prevFreezeFigures.current.length !== freezeFigures.length &&
        freezeFigures.some((value) => !prevFreezeFigures.current.includes(value))
    ) {
      freezeAudio.current?.play().catch(console.log);
      prevFreezeFigures.current = freezeFigures;
      console.log("FREEZE");
    } else if (
        prevDefendFigures.current.length !== defendFigures.length &&
        defendFigures.some((value) => !prevDefendFigures.current.includes(value)) &&
        data.defendFigureStepsCounter[currentSide ?? Colors.WHITE] !== 1
  ) {
      console.log("DEFEND");
      prevDefendFigures.current = defendFigures;
    } else if (
        data.defendFigureStepsCounter[currentSide ?? Colors.WHITE] === 1 &&
        !!currentSideDefendFigure
    ) {
      console.log("DEFEND 2");
    } else if (
        prevSpeededFigures.current.length !== speededFigures.length &&
        speededFigures.some((value) => !prevSpeededFigures.current.includes(value))
    ) {
      speededAudio.current?.play().catch(console.log);
      prevSpeededFigures.current = speededFigures;
      console.log("SPEED");
    } else {
      if (prevFigures.current && prevFigures.current !== figures) {
        prevFreezeFigures.current = freezeFigures;
        prevSpeededFigures.current = speededFigures;
        prevDefendFigures.current = defendFigures;


        console.log("STEP");
        stepAudio.current?.play().catch(console.log);
        prevFigures.current = figures;
      }
    }

    setCells(data.cells);
    setCurrentSide(data.currentSide);
    setPlayer(data.player);
    setBlackCooldown(data.blackCooldown);
    setWhiteCooldown(data.whiteCooldown);
    setWinnerSide(data.winnerSide);
    setCheckedSide(data.checkedSide);
    setEnemy(data.enemy);
    setWhiteTimer(data.whiteTimer);
    setBlackTimer(data.blackTimer);
    setSpeededUpFigureStepsCounter(data.speededUpFigureStepsCounter);
    setDefendFigureStepsCounter(data.defendFigureStepsCounter);
    setIsNobodyWin(data.isNobodyWin);
  });

  function loadSound(src: string) {
    let sound: any = document.createElement("audio");

    if ("src" in sound) {
      sound.autoplay = false;
    } else {
      sound = document.createElement("bgsound");
      sound.volume = -10000;
      sound.play = function () {
        this.src = src;
        this.volume = 0;
      };
    }

    sound.src = src;
    document.body.appendChild(sound);
    return sound;
  }

  useWebsocketEvent(ClientEvent.DefendSound, () => {
    defendSecondlyAudio.current?.play().catch(console.log);
  });

  return (
      <BoardBlock>
        <BoardContent side={player?.side ?? Colors.WHITE}>
          <BoardController
              player={player}
              enemy={enemy}
              currentSide={currentSide}
              side={Colors.BLACK}
              onClick={changeMagic}
              number={magicNumber}
              magic={chosenMagic}
              cooldown={blackCooldown}
          />

          <BoardTimer side={Colors.BLACK} player={player}>
            {moment(blackTimerValue).format("mm : ss")}
          </BoardTimer>

          <BoardController
              player={player}
              enemy={enemy}
              currentSide={currentSide}
              side={Colors.WHITE}
              onClick={changeMagic}
              magic={chosenMagic}
              number={magicNumber}
              cooldown={whiteCooldown}
          />

          <BoardTimer side={Colors.WHITE} player={player}>
            {moment(whiteTimerValue).format("mm : ss")}
          </BoardTimer>

          {/* <BoardFrame src="/images/frame.png" alt="frame" /> */}

          {initCells}
          {initFigures}

          {(winnerSide || isNobodyWin) && (
              <BoardEnd
                  player={player}
                  result={isNobodyWin ? "between" : winnerSide === player?.side ? "win" : "lose"}
                  onClick={() => navigate(`/board/${boardId}/history`)}
              />
          )}
        </BoardContent>
      </BoardBlock>
  );
};

export default Board;